import React from 'react'
import { graphql } from 'gatsby'
import classnames from 'classnames'
import routes from '../routes'

import detectColor, { COLORS } from '../ui/identity/colors'

/* ====================================================== */
/*                       Components                       */
/* ====================================================== */

import { Link } from 'gatsby'
import Layout from '../ui/layout/layout'
import SEO from '../ui/components/seo/seo'
import Image from '../ui/elements/image/image'
import Text from '../ui/elements/text/text'
import ArticleInformation from '../ui/components/article_information/article_information'
import Newsletter from '../ui/components/newsletter_subscription/newsletter_subscription'

/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */

import styles from './category_page.module.scss'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

const Category = ({ data, location }) => {
	const { title, metaDescription, color, slug } = data.category
	const posts = data.posts.edges

	return (
		<Layout location={location}>
			<SEO title={title} description={metaDescription} url={routes.category({ slug: slug, base: true })} />
			<div className={styles.CategoryWrapper}>
				<div className={classnames(styles.HeaderWrapper, styles[`${detectColor(color)}`])}>
					<div className={styles.HeaderInner}>
						<Text as="h1" size="56" color={COLORS.SNOW} chunky>
							{title}
						</Text>
					</div>
				</div>

				<div className={styles.PostsListWrapper}>
					{posts.length > 0 &&
						posts.map(({ node }, index) => (
							<div
								className={classnames(styles.PostWrapper, { [styles.OddPost]: index % 2 === 1 })}
								key={index}
							>
								<div>
									<Link
										tabIndex="-1"
										to={routes.post({ categorySlug: node.category.slug, postSlug: node.slug })}
									>
										<Image
											fluid={node.heroImage.fluid}
											wrapperClassName={styles.HeroImageWrapper}
											alt={node.heroImage.description}
											key={index}
										/>
									</Link>
									<div className={styles.PostInfo}>
										<ArticleInformation {...node} key={index} />
									</div>
								</div>
							</div>
						))}
				</div>
				{/* <section title={`Newsletter subscription`} className={styles.NewsletterSection}>
					<Newsletter />
				</section> */}
			</div>
		</Layout>
	)
}

export default Category

export const pageQuery = graphql`
	query ($slug: String!) {
		category: contentfulCategory(slug: { eq: $slug }) {
			title
			slug
			metaDescription
			color
		}
		posts: allContentfulPost(
			filter: { category: { slug: { eq: $slug } } }
			limit: 100
			sort: { fields: [publishDate], order: DESC }
		) {
			edges {
				node {
					title
					slug
					publishDate
					minutesReading
					description
					heroImage {
						description
						fluid(maxWidth: 728) {
							...GatsbyContentfulFluid
						}
					}
					category {
						slug
					}
				}
			}
		}
	}
`
